
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { InitHome } from "@/types/home";
import { RouteLocationNormalized } from "vue-router";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {
    isDay: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit, attrs, slots }) {
    const { isDay } = toRefs(props);
    const route = useRoute();
    const router = useRouter();
    let HomeObj = reactive(new InitHome());
    const editableTabsValue = ref(route.name); // 以字符串形式初始化

    onMounted(() => {
      const savedTabs = sessionStorage.getItem('tabsList');
      if (savedTabs) {
        HomeObj.TabsList = JSON.parse(savedTabs);
        HomeObj.TabsList = HomeObj.TabsList.filter(item => item.name !== 'login')
        const activeTab = sessionStorage.getItem('activeTab');
        editableTabsValue.value = activeTab || 'home';
      } else {
        addTab(route); // 如果没有保存的标签，则添加当前路由对应的标签
      }
    });
    const removeTab = (targetName: string) => {
      const tabs = HomeObj.TabsList;
      let activeName = editableTabsValue.value;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              router.push(nextTab.fullPath);
            }
          }
        });
      }
      editableTabsValue.value = activeName;
      HomeObj.TabsList = tabs.filter((tab) => tab.name !== targetName);
      sessionStorage.setItem('tabsList', JSON.stringify(HomeObj.TabsList));
    };
    const addTab = (to: RouteLocationNormalized) => {
      const tabName = to.name as string;
      const tabTitle = getTitleByRoute(to);
      const existingTab = HomeObj.TabsList.find((tab) => tab.name === tabName);
      if (!existingTab) {
        HomeObj.TabsList.push({ 
          name: tabName, 
          title: tabTitle,
          fullPath: to.fullPath // 存储完整路径
        });
      } else {
        // 更新现有标签的 fullPath
        existingTab.fullPath = to.fullPath;
      }
      editableTabsValue.value = tabName;
      sessionStorage.setItem('tabsList', JSON.stringify(HomeObj.TabsList));
      sessionStorage.setItem('activeTab', to.name as string);
    };
    const getTitleByRoute = (route: RouteLocationNormalized): any => {
      // 根据需要自定义获取标题的逻辑
      return route.meta.title;
    };
    const clickTab = (tab: any) => {
      const targetTab = HomeObj.TabsList.find(t => t.name === tab.props.name);
      if (targetTab) {
        router.push(targetTab.fullPath);
      }
    };

    router.beforeEach((to, from, next) => {
      addTab(to);
      editableTabsValue.value = to.name as string;
      next();
    });
    return {
      editableTabsValue,
      ...toRefs(HomeObj),
      removeTab,
      addTab,
      clickTab,
    };
  },
});
