
import { defineComponent, ref, reactive, toRefs, onMounted, inject, getCurrentInstance, compile } from "vue";
import { useRouter } from "vue-router";
import headerTop from "@/components/home/head/top.vue";
import headerBottom from "@/components/home/head/bottom.vue";
import { fa } from "element-plus/es/locale";
export default defineComponent({
  components: {
    headerTop,
    headerBottom,
  },
  setup() {
    const { proxy } = getCurrentInstance() as any;

    let isCollapse = ref(false);
    let isDay = ref(true)
    const router = useRouter();
    // const pathList = router.getRoutes().filter((v) => v.meta.isShow);
    const dirList = ref([])
    onMounted(() => {
      getPathList()
    })
    const getPathList = () =>{
      dirList.value = [
        {
            "is_keepalive": true,
            "visible": true,
            "always_show": true,
            "menu_type": 1,
            "type": 0,
            "status": 1,
            "sort": 1,
            "id": 6,
            "parent_id": 0,
            "project_id": 4,
            "name": "审核管理",
            "path": "/",
            "component": "",
            "component_name": "",
            "icon": "iconfont icon-guanfangbanben",
            "insert_Time": "0001-01-01T00:00:00Z",
            "permission": "",
            "children": [
              {
                    "is_keepalive": true,
                    "visible": true,
                    "always_show": true,
                    "menu_type": 2,
                    "type": 0,
                    "status": 1,
                    "sort": 1,
                    "id": 7,
                    "parent_id": 6,
                    "project_id": 4,
                    "name": "活动列表",
                    "path": "applist",
                    "component": "views/auditing/activeList",
                    "component_name": "activeList",
                    "icon": "iconfont icon-zhanghaoquanxianguanli",
                    "insert_Time": "0001-01-01T00:00:00Z",
                    "permission": "system:user",
                    "children": null
                },
            ]
        }
      ]
    }
    const open = (isOpen: boolean) => {
      isCollapse.value = isOpen;
    };
    const sendDay =(Day: boolean) =>{
      isDay.value = Day
      if (Day == false) {
        document.documentElement.style.setProperty('--el-bg-color-overlay', '#2b2b2b');
        document.documentElement.style.setProperty('--el-bg-color', '#2b2b2b');
        document.documentElement.style.setProperty('--el-fill-color-blank', '#2b2b2b');
        document.documentElement.style.setProperty('--el-tree-text-color', '#ffffff');

        document.documentElement.style.setProperty('--el-fill-color-light', 'rgb(34, 34, 34)');
      } else {
        document.documentElement.style.setProperty('--el-bg-color-overlay', '#ffffff');
        document.documentElement.style.setProperty('--el-bg-color', '#ffffff');
        document.documentElement.style.setProperty('--el-fill-color-light', '#f5f7fa');

        document.documentElement.style.setProperty('--el-fill-color-blank', '#ffffff');
        document.documentElement.style.setProperty('--el-tree-text-color', '#606266');
      }
    };
    const toHome = () => {
      router.push('/index')
    }
    // 路由守卫
    return {
      isCollapse,
      // pathList,
      isDay,
      dirList,
      open,
      sendDay,
      toHome
    };
  },
});
