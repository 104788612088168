
const auditingRoutes: Array<any> = [
    {
        path: "applist",
        name: "applist",
        meta: {
          isShow: true,
          keepAlive: true,
          title: "活动列表",
          icon: "iconfont icon-zhanghaoquanxianguanli",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/auditing/activeList.vue"),
    },
    {
      path: "activeDetail",
      name: "activeDetail",
      meta: {
        isShow: true,
        title: "活动详情",
        keepAlive: true,
        icon: "iconfont icon-zhanghaoquanxianguanli",
      },
      component: () =>
        import(/* webpackChunkName: "user" */ "../views/auditing/activeDetail.vue"),
    },
    {
      path: "activeHistory",
      name: "activeHistory",
      meta: {
        isShow: true,
        title: "活动历史",
        keepAlive: true,
        icon: "iconfont icon-zhanghaoquanxianguanli",
      },
      component: () =>
        import(/* webpackChunkName: "user" */ "../views/auditing/activeHistory.vue"),
    },
    {
      path: "gift",
      name: "gift",
      meta: {
        isShow: true,
        title: "评奖",
        keepAlive: true,
        icon: "iconfont icon-zhanghaoquanxianguanli",
      },
      component: () =>
        import(/* webpackChunkName: "user" */ "../views/auditing/gift.vue"),
    },
];
export default auditingRoutes;